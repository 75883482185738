<template>
  <CCard>
    <CCardHeader>My widgets</CCardHeader>
    <CCardBody>
      <CModal :show.sync="open_facebook_preview" color="warning">
        <template slot="header">
          <h4 class="text-left">Preview Facebook Post</h4>
          <CButton class="float-right text-white" @click="open_facebook_preview=false"
            >X</CButton
          >
        </template>
        <div slot="default">
          <img :src="url_preview_facebook" class="preview-image" alt="Preview Facebook Post" />
        </div>
        <div slot="footer"><CButton @click="open_facebook_preview=false">Close</CButton></div>
      </CModal>
      <CModal :show.sync="open_twitter_preview" color="warning">
        <template slot="header">
          <h4 class="text-left">Preview Twitter Post</h4>
          <CButton class="float-right text-white" @click="open_twitter_preview=false"
            >X</CButton
          >
        </template>
        <div slot="default">
          <img :src="url_preview_twitter" class="preview-image" alt="Preview Twitter Post" />
        </div>
        <div slot="footer"><CButton @click="open_twitter_preview=false">Close</CButton></div>
      </CModal>
      <CAlert color="success" :show.sync="alert_link" class="alert-dismissible">
        The link URL is copied to the clipboard
        <CButton
          class="position-absolute"
          color="secondary"
          style="right: 10px; top: 50%; transform: translateY(-50%)"
          @click="alert_link = false"
        >
          Close
        </CButton>
      </CAlert>
      <CAlert color="success" :show.sync="alert_code" class="alert-dismissible">
        The widget code is copied to the clipboard
        <CButton
          class="position-absolute"
          color="secondary"
          style="right: 10px; top: 50%; transform: translateY(-50%)"
          @click="alert_code = false"
        >
          Close
        </CButton>
      </CAlert>
      <h3>Social Media Friendly List</h3>
      <p>
        A link designed to be shareable on the popular social media websites
        like Facebook and Twitter!
      </p>
      <CRow>
        <CCol class="text-center">
          <h2>
            <input
              type="hidden"
              id="social-share-link-hidden"
              :value="previewUrl"
            />
            <a href="#" id="social-share-link" class="social-share-link">
              <!-- TODO: replace this URL with actual link to the Landing page -->
              {{ previewUrl || "Loading..." }}
            </a>
            <CButton color="primary" @click="onCopyLinkURL">!</CButton>
          </h2>
        </CCol>
      </CRow>
      <CRow class="mt-4">
        <CCol col="6" class="text-center">
          <CButton color="info">Facebook</CButton>
        </CCol>
        <CCol col="6" class="text-center">
          <CButton color="info">Twitter</CButton>
        </CCol>
      </CRow>

      <CRow class="mt-4">
        <CCol col="12" class="text-center">
          <CButtonGroup block class="col-12">
            <CButton color="dark" variant="outline" @click="open_facebook_preview=true"
              >Preview Facebook Post</CButton
            >
            <CButton color="dark" variant="outline" @click="open_twitter_preview=true"
              >Preview Twitter Post</CButton
            >
          </CButtonGroup>
        </CCol>
      </CRow>

      <CRow class="mt-4">
        <CCol>
          <CTabs>
            <CTab title="Horizontal" active>
              <CRow class="p-3">
                <CCol>
                  <h4>Horizontal widget for your website</h4>
                  <p>Copy and paste the HTML code below</p>
                  <textarea
                    class="codetext bg-light"
                    id="code-horizontal"
                    v-model="code_horizontal"
                  ></textarea>
                  <CButton color="secondary" @click="onCopyHorizontal"
                    >Copy
                  </CButton>
                  <hr />
                  <div v-html="code_horizontal"></div>
                </CCol>
              </CRow>
            </CTab>
            <CTab title="Vertical" active>
              <CRow class="p-3">
                <CCol>
                  <h4>Vertical widget for your website</h4>
                  <p>Copy and paste the HTML code below</p>
                  <textarea
                    class="codetext bg-light"
                    id="code-vertical"
                    v-model="code_vertical"
                  ></textarea>
                  <CButton color="secondary" @click="onCopyVertical"
                    >Copy
                  </CButton>
                  <hr />
                  <div v-html="code_vertical"></div>
                </CCol>
              </CRow>
            </CTab>
            <CTab title="Advanced" active>
              <h4>Advanced widget for your website</h4>
              <hr />
            </CTab>
          </CTabs>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import _ from "underscore";
import { mapState } from "vuex";

export default {
  props: {
    agentId: String,
  },
  data: function () {
    return {
      alert_link: false,
      alert_code: false,
      open_facebook_preview: false,
      open_twitter_preview: false,
      url_preview_facebook:
        "https://s3.amazonaws.com/ims-site/stock/samples/home-val-FacebookPostSample.jpg",
      url_preview_twitter:
        "https://s3.amazonaws.com/ims-site/stock/samples/home-val-TwitterCardSample.jpg",
      code_horizontal:
        '<iframe src="' +
        window.location.origin +
        "/admin/w/horizontal/" +
        this.agentId +
        '" height="700px" width="fit-content" frameborder="0" style="max-height:1154px;overflow:hidden" scrolling="no"></iframe>',
      code_vertical:
        '<iframe src="' +
        window.location.origin +
        "/admin/w/vertical/" +
        this.agentId +
        '" width="304px" height="1154px" frameborder="0" scrolling="auto" style="max-height:1154px;overflow:hidden;"></iframe>',
    };
  },
  created() {
    this.$store.dispatch("Agent/loadAgentData", { agentId: 1 }, { root: true });
    this.$store.dispatch("Agent/loadSampleLink", 1, { root: true });
  },
  computed: {
    ...mapState({
      sampleLink: (state) => state.Agent.sampleLink,
    }),
    agentid() {
      return this.getAgentId();
    },
    previewUrl() {
      return this.sampleLink
        ? `${window.location.origin}/result/${this.sampleLink}`
        : false;
    },
  },
  methods: {
    getAgentId: function () {
      return this.$store.state.Agent.agent.id;
    },
    onCopyLinkURL: function () {
      const copyText = document.getElementById("social-share-link-hidden");
      const self = this;
      navigator.clipboard.writeText(copyText.value).then(function () {
        self.alert_link = true;
      });
    },
    onCopyHorizontal: function () {
      const copyText = document.getElementById("code-horizontal");
      const self = this;
      navigator.clipboard.writeText(copyText.value).then(function () {
        self.alert_code = true;
      });
    },
    onCopyVertical: function () {
      const copyText = document.getElementById("code-vertical");
      const self = this;
      navigator.clipboard.writeText(copyText.value).then(function () {
        self.alert_code = true;
      });
    },
  },
};
</script>

<style scoped>
.social-share-link {
  font-size: 150%;
  font-weight: 150%;
  color: #002460;
}

.codetext {
  width: 100%;
  height: 5em;
}

.preview-image {
  width:100%;
}
</style>
